import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import fragment from "./shader/fragment.glsl";
import vertex from "./shader/vertex.glsl";
import GUI from "lil-gui";
import gsap from "gsap";

import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import model from "../model/full.glb";
// import matcap from '../img/matcap15.jpg'
// import eq from '../img/eq.jpg'

function getRandomAxis() {
  return new THREE.Vector3(
    Math.random() - 0.5,
    Math.random() - 0.5,
    Math.random() - 0.5
  ).normalize();
}
const sign = function (n) {
  return n === 0 ? 1 : n / Math.abs(n);
};

function getCentroid(geometry) {
  // console.log(geometry.attributes.position.array);
  let ar = geometry.attributes.position.array;
  let len = ar.length;
  let x = 0,
    y = 0,
    z = 0;
  for (let i = 0; i < len; i = i + 3) {
    x += ar[i];
    y += ar[i + 1];
    z += ar[i + 2];
  }
  return { x: (3 * x) / len, y: (3 * y) / len, z: (3 * z) / len };
}

export default class Sketch {
  constructor(options) {
    this.scene = new THREE.Scene();

    this.inverted = true;
    this.container = options.dom;

    this.onLoad = options.onLoad;
    this.onClick = options.onClick;

    this.controlExplosion = true;

    // get colors
    // this.surfaceColor = this.container.getAttribute('data-surface').substring(1);
    this.surfaceColor = options.surface;
    // this.insideColor = this.container.getAttribute('data-inside').substring(1);
    this.insideColor = options.inside;
    // this.backgroundColor = this.container.getAttribute('data-background');
    this.backgroundColor = options.background;
    this.surfaceColor = new THREE.Color(parseInt("0x" + this.surfaceColor));
    this.insideColor = new THREE.Color(parseInt("0x" + this.insideColor));
    this.insideColors = [];
    this.colors = options.insideArray.map(
      (e) => new THREE.Color(parseInt("0x" + e))
    );

    this.raycaster = new THREE.Raycaster();
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: this.backgroundColor === "transparent",
    });
    if (this.backgroundColor === "transparent") {
      this.renderer.setClearColor(0x000000, 0);
    } else {
      this.backgroundColor = parseInt("0x" + this.backgroundColor, 16);
      this.renderer.setClearColor(this.backgroundColor, 1);
    }

    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.mouseX = 0;
    this.mouseY = 0;
    this.targetmouseX = 0;
    this.targetmouseY = 0;

    this.current = 0;
    this.target = 0;
    this.renderer.setSize(this.width, this.height);

    // console.log(this.surfaceColor);
    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.01,
      100
    );

    this.camera.position.set(0, -0.1, 1);
    this.camera.lookAt(0, -0.1, 0.0);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;
    this.loader = new GLTFLoader();
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.setupResize();

    this.setupcubeTexture();
    this.resize();
    this.addObjects();
    this.animate();
    this.load();
    this.settings();
    this.addLights();
    this.mouse();
  }

  addLights() {
    this.lightAmbient = new THREE.AmbientLight(0xffffff, 1.6); // soft white light
    this.scene.add(this.lightAmbient);
    this.dir1 = new THREE.DirectionalLight(0xffffff, 1);
    this.scene.add(this.dir1);
    this.dir1.position.set(-1, 0.5, 0);

    this.dir2 = new THREE.DirectionalLight(0xffffff, 1);
    this.scene.add(this.dir2);
    this.dir2.position.set(0, 1, 0);

    this.dir3 = new THREE.DirectionalLight(0xffffff, 1);
    this.scene.add(this.dir3);
    this.dir3.position.set(0.5, -0.5, 0);
  }

  settings() {
    let that = this;
    this.settings = {
      progress: 0,
    };
    // this.gui = new GUI();
    // this.gui.add(this.settings, 'progress',0,2,0.001);
  }

  load() {
    let that = this;
    let i = 0;
    let parent;
    let pos = new THREE.Vector3(0, 0, 0);
    let poses = [];
    this.voron = [];

    this.loader.load(
      model,
      function (gltf) {
        console.warn(gltf.scene, "scene");

        // this.sculptureMaterial;

        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            // child.material = new THREE.MeshBasicMaterial( {wireframe:true,depthTest: false,color: 0x00ff00} );
          }
          if (child.name === "Voronoi_Fracture") {
            that.obj = child;

            // console.log(child,'child');
            if (child.children[0].children.length > 2) {
              child.children.forEach((f) => {
                f.children.forEach((m) => {
                  that.voron.push(m.clone());
                  console.log(m, "mmm");
                });
              });
            } else {
              child.children.forEach((m) => {
                // console.log(m)
                that.voron.push(m.clone());
                // console.log();
                // that.sculptureMaterial = m.children[0].material;
              });
            }

            // that.updateMaterial(that.sculptureMaterial);
          }
        });

        that.geoms = [];
        that.geoms1 = [];
        let j = 0;
        // console.log(that.voron,'voron');
        that.voron = that.voron.filter((v) => {
          if (v.isMesh) return false;
          else {
            j++;

            let vtempo = that.processSurface(v, j);

            if (!that.inverted) {
              that.geoms1.push(vtempo.surface);
              that.geoms.push(vtempo.volume);
            } else {
              that.geoms.push(vtempo.surface);
              that.geoms1.push(vtempo.volume);

              // let me = new THREE.Mesh(vtempo.volume,new THREE.MeshBasicMaterial({color:0xff0000} ));
              // that.scene.add(me);
            }

            return true;
          }
        });

        let s = mergeBufferGeometries(that.geoms, false);

        that.mesh = new THREE.Mesh(
          s,
          that.material
          // s,that.sculptureMaterial
        );
        console.log(that.sculptureMaterial);
        // that.mesh.scale.set(2,2,2);
        that.scene.add(that.mesh);

        let s1 = mergeBufferGeometries(that.geoms1, false);
        that.mesh1 = new THREE.Mesh(
          // s1,new THREE.MeshBasicMaterial({color:0xff0000} )
          s1,
          that.material1
          // s1,that.sculptureMaterial
        );
        // this.sculptureMaterial
        // that.mesh1.scale.set(2,2,2);
        that.scene.add(that.mesh1);
        that.onLoad();
      },
      undefined,
      function (e) {
        console.error(e);
      }
    );
  }

  // begin surface
  processSurface(v, j) {
    let c = v.position;
    let vtemp, vtemp1;
    // geometry changes
    vtemp = v.children[0].geometry.clone();
    // console.log('temp',vtemp)
    // vtemp = vtemp.applyMatrix( new THREE.Matrix4().makeRotationY(Math.PI/2) );
    vtemp = vtemp.applyMatrix4(
      new THREE.Matrix4().makeTranslation(c.x, c.y, c.z)
    );
    // let vtemp = v.children[0].geometry;
    vtemp1 = v.children[1].geometry;
    // vtemp1 = vtemp1.applyMatrix( new THREE.Matrix4().makeRotationY(Math.PI/2) );
    vtemp1 = vtemp1
      .clone()
      .applyMatrix4(new THREE.Matrix4().makeTranslation(c.x, c.y, c.z));

    let len = v.children[0].geometry.attributes.position.array.length / 3;
    let len1 = v.children[1].geometry.attributes.position.array.length / 3;
    //  id
    let offset = new Array(len).fill(j / 100);
    vtemp.setAttribute(
      "offset",
      new THREE.BufferAttribute(new Float32Array(offset), 1)
    );

    let offset1 = new Array(len1).fill(j / 100);
    vtemp1.setAttribute(
      "offset",
      new THREE.BufferAttribute(new Float32Array(offset1), 1)
    );

    // axis
    let axis = getRandomAxis();
    let axes = new Array(len * 3).fill(0);
    let axes1 = new Array(len1 * 3).fill(0);
    for (let i = 0; i < len * 3; i = i + 3) {
      axes[i] = axis.x;
      axes[i + 1] = axis.y;
      axes[i + 2] = axis.z;
    }
    vtemp.setAttribute(
      "axis",
      new THREE.BufferAttribute(new Float32Array(axes), 3)
    );
    // volume axes
    for (let i = 0; i < len1 * 3; i = i + 3) {
      axes1[i] = axis.x;
      axes1[i + 1] = axis.y;
      axes1[i + 2] = axis.z;
    }
    vtemp1.setAttribute(
      "axis",
      new THREE.BufferAttribute(new Float32Array(axes1), 3)
    );

    // centroid
    let centroidVector = getCentroid(vtemp1);
    // console.log({centroidVector});
    let centroid = new Array(len * 3).fill(0);
    let centroid1 = new Array(len1 * 3).fill(0);
    for (let i = 0; i < len * 3; i = i + 3) {
      centroid[i] = centroidVector.x;
      centroid[i + 1] = centroidVector.y;
      centroid[i + 2] = centroidVector.z;
    }
    for (let i = 0; i < len1 * 3; i = i + 3) {
      centroid1[i] = centroidVector.x;
      centroid1[i + 1] = centroidVector.y;
      centroid1[i + 2] = centroidVector.z;
    }
    vtemp.setAttribute(
      "centroid1",
      new THREE.BufferAttribute(new Float32Array(centroid), 3)
    );
    vtemp1.setAttribute(
      "centroid1",
      new THREE.BufferAttribute(new Float32Array(centroid1), 3)
    );

    // colors
    let colors = new Array(len * 3).fill(0);
    let colors1 = new Array(len1 * 3).fill(0);
    let color = this.colors[Math.floor(Math.random() * this.colors.length)];
    for (let i = 0; i < len * 3; i = i + 3) {
      // color = this.colors[2];
      colors[i] = color.r;
      colors[i + 1] = color.g;
      colors[i + 2] = color.b;
    }
    for (let i = 0; i < len1 * 3; i = i + 3) {
      // color = this.colors[2];
      colors1[i] = color.r;
      colors1[i + 1] = color.g;
      colors1[i + 2] = color.b;
    }
    vtemp1.setAttribute(
      "colors",
      new THREE.BufferAttribute(new Float32Array(colors1), 3)
    );
    vtemp.setAttribute(
      "colors",
      new THREE.BufferAttribute(new Float32Array(colors), 3)
    );

    return { surface: vtemp, volume: vtemp1 };
  }
  // end surface

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  setupcubeTexture() {
    // let that = this;
    // let path = 'img/newsky/';
    // let format = '.jpg';
    // let urls1 = [
    //   path + 'px' + format, path + 'nx' + format,
    //   path + 'py' + format, path + 'ny' + format,
    //   path + 'pz' + format, path + 'nz' + format
    // ];
    // this.textureCube = new THREE.CubeTextureLoader().load( urls1 );
    // return new Promise(resolve => {
    //   that.textureCube1 = new THREE.CubeTextureLoader().load( urls1, resolve );
    // });
    // this.textureCube1.format = THREE.RGBFormat;
  }

  getMaterial() {
    return new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        progress: { type: "f", value: 0 },
        inside: { type: "f", value: 0 },
        surfaceColor: { type: "v3", value: this.surfaceColor },
        insideColor: { type: "v3", value: this.insideColor },
        matcap: { type: "t", value: null },
        tCube: { value: this.textureCube },
        tReflectionTexture: { value: null },
        pixels: {
          type: "v2",
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        uvRate1: {
          value: new THREE.Vector2(1, 1),
        },
      },
      // wireframe: true,
      transparent: true,
      // flatShading: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });
  }

  addObjects() {
    let that = this;
    this.material = this.getMaterial();

    this.material1 = this.getMaterial();
    this.material1.uniforms.inside.value = 1;

    this.geometry = new THREE.PlaneGeometry(4, 4, 1, 1);
    this.geometry = new THREE.SphereBufferGeometry(0.5, 32, 32);

    this.points = new THREE.Mesh(this.geometry, this.material);
    // this.scene.add(this.points);
  }

  mouse() {
    document.addEventListener("mousemove", (e) => {
      this.targetmouseX = (2 * (e.clientX - this.width / 2)) / this.width;
      this.targetmouseY = (2 * (e.clientY - this.height / 2)) / this.height;
      let dist = Math.sqrt(this.targetmouseX ** 2 + this.targetmouseY ** 2);
      // dist = this.targetmouseX;
      // console.log(dist);
      // this.settings.progress = dist*dist;
      this.target = dist * dist;
    });

    document.addEventListener("touchmove", (e) => {
      this.targetmouseX = (e.touches[0].clientX / this.width) * 2 - 1;
      this.targetmouseY = -(e.touches[0].clientY / this.height) * 2 + 1;
      let dist = Math.sqrt(this.targetmouseX ** 2 + this.targetmouseY ** 2);
      // dist = this.targetmouseX;
      this.settings.progress = dist * dist;
    });
  }

  animate() {
    this.time += 0.05;
    this.mouseX += (this.targetmouseX - this.mouseX) * 0.2;
    this.current += (this.target - this.current) * 0.2;
    let sign = Math.sign(this.mouseX);

    // this.material.uniforms.progress.value = this.current*6;

    let ta = Math.abs(this.mouseX);
    let ta1 = ta;
    if(!this.controlExplosion){
      this.material.uniforms.progress.value = 6 * ta1 * ta1;
      this.material1.uniforms.progress.value = 6 * ta1 * ta1;
    }
   
    this.scene.rotation.y =
      Math.PI / 2 + 0.5 * -ta * (2 - ta) * Math.PI * Math.sign(this.mouseX);

    // this.scene.rotation.y = Math.PI/2 + this.current;
    if (this.sculptureMaterial && this.sculptureMaterial.userData.shader) {
      this.sculptureMaterial.userData.shader.uniforms.progress.value =
        this.current;
    }

    requestAnimationFrame(this.animate.bind(this));
    this.render();
  }
}

// new Sketch('container');
