#define RECIPROCAL_PI2 0.15915494
uniform float time;
uniform float progress;
uniform float inside;
uniform vec3 surfaceColor;
uniform vec3 insideColor;
varying vec3 viewPosition;

varying vec2 vUv;
varying vec2 vUv1;



// matcap
uniform samplerCube tCube;
uniform sampler2D tReflectionTexture;
uniform sampler2D matcap;
varying vec3 eye;
varying vec3 vNormal;
varying vec3 vColor;
varying vec3 vPosition;

varying vec3 vReflect;

// varying float vTemp;

vec4 mirror() {
    vec2 sampleUV;
    sampleUV.y = clamp(0.,1., vReflect.y * 0.5 + 0.5 );
    sampleUV.x = atan( vReflect.z, vReflect.x ) * RECIPROCAL_PI2 + 0.5;
    return texture2D( tReflectionTexture, sampleUV );
}
void main()	{

	vec3 xTangent = dFdx( viewPosition );
    vec3 yTangent = dFdy( viewPosition );
    vec3 faceNormal = normalize( cross( xTangent, yTangent ) );

	// matcap calc
	vec3 r = reflect( eye, vNormal );
	float m = 2. * sqrt( pow( r.x, 2. ) + pow( r.y, 2. ) + pow( r.z + 1., 2. ) );
	vec2 vN = r.xy / m + .5;
	vec3 base = texture2D( matcap, vN ).rgb;
	// vec4 reflectedColor = textureCube( tCube, vec3( -vReflect.x, vReflect.yz ) );
	vec4 reflectedColor = mirror();
	// end matcap

	// vec3 light = normalize(vec3(1200.,1000.,1000.));
	// vec3 light1 = normalize(vec3(-1200.,100.,100.));
	vec3 light = normalize(vec3(12.,-10.,10.));
	vec3 light1 = normalize(vec3(-12.,5.,10.));
	float l = clamp(dot(light, faceNormal),0.5,1.);
	l += clamp(dot(light1, faceNormal),0.5,1.)/2.;
	// l /= 2.;
	
	if(inside<0.5){

		gl_FragColor = vec4(1.,0.,0.,1.);
		// gl_FragColor = vec4(l,l,l,1.)*vec4(surfaceColor,1.);
		gl_FragColor = vec4(vNormal,1.);

		gl_FragColor = vec4(base,1.);
		// gl_FragColor = vec4(vPosition.x/7.,vPosition.y/7.,0.,1.);
		vec4 mat = texture2D( matcap, vUv );
		gl_FragColor.rgb =insideColor*max(0.5,l);

	// gl_FragColor = vec4(1.,0.,0.,1.);

		
	} else{
		// if(progress<0.002) discard;
		gl_FragColor = vec4(abs(vNormal),1.);
		// gl_FragColor = vec4(l,l,l,1.)*vec4(1.,0.,0.,1.);
		
		gl_FragColor = reflectedColor*vec4(vColor,1.);
		gl_FragColor = vec4(vColor,1.);
		gl_FragColor = vec4(base,1.);
		gl_FragColor = vec4(l,l,l,1.)*vec4(surfaceColor,1.);

	}

	// gl_FragColor = texture2D( matcap, vUv );
	// gl_FragColor = vec4(base,1.);

	// gl_FragColor = vec4(vNormal,1.);
	
	// gl_FragColor = vec4(-vReflect.x, vReflect.yz ,1.);
	// gl_FragColor = reflectedColor;
	// gl_FragColor = vec4(l,l,l,1.);
	// gl_FragColor = vec4(progress,0.,0.,1.);
	// gl_FragColor = vec4(faceNormal,1.);
}