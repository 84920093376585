import Sketch from "./module";
import gsap from "gsap";

let animation = new Sketch({
  dom: document.getElementById("container"),
  surface: "FFFFFF",
  inside: "F68912",
  insideArray: ["ff0000"],
  background: "transparent",
  onClick: () => {
    alert("clicked");
  },
  onLoad: () => {
    document.body.classList.add("explosion_loaded");
    document.body.classList.remove("loading");
  },
});

let isOpen = false;
let enterCtrl = document.querySelector(".animation-enter");

enterCtrl.addEventListener("click", () => {
  isOpen = true;
  let tl = gsap.timeline({
    onComplete: ()=>{
        animation.controlExplosion = true
    }
  });
  tl.to(".triggerbutton", { opacity: 0, duration: 0.5, scale: 0.5 });
  tl.to(
    [
      animation.material.uniforms.progress,
      animation.material1.uniforms.progress,
    ],
    {
      duration: 4,
      value: 3,
      ease: "expo.out",
    }
  );
  tl.fromTo(
    ".animation-start",
    {
      opacity: 0,
      scale: 0.3,
    },
    {
      opacity: 1,
      scale: 1,
      duration: 0.5,
      ease: "expo.out",
      immediateRender: false,
    },
    0.5
  );

  // Replis cristal
  /*
  tl.to(
    [
      animation.material.uniforms.progress,
      animation.material1.uniforms.progress,
    ],
    {
      duration: 3,
      value: 0,
      ease: "power4.inOut",
    },
    4
  );
  */

  /*
  tl.fromTo(
    ".animation-start",
    {
      opacity: 1,
      scale: 1,
    },
    {
      opacity: 0,
      scale: 0.3,
      duration: 1,
      ease: "expo.out",
      immediateRender: false,
    },
    7
  );
  */
  tl.fromTo(
    ".animation-text",
    {
      opacity: 0,
      scale: 0.5,
    },
    {
      opacity: 1,
      scale: 1,
      duration: 1,
      ease: "expo.out",
      immediateRender: false,
    },
    3
  );
});

enterCtrl.addEventListener("mouseenter", () => {
  if (isOpen) return;
  gsap.to(animation.camera.position, {
    z: 0.9,
    ease: "power3.out",
    duration: 0.6,
  });
});
enterCtrl.addEventListener("mouseleave", () => {
  if (isOpen) return;
  gsap.to(animation.camera.position, {
    z: 1,
    ease: "power3.out",
    duration: 0.6,
  });
});
